import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import NonbmA from "src/components/modules/nonbmA"
import CtaDisclosureSearch from "@tightrope/ctadisclosuresearch"
import {isIeEdge,isChrome,isFirefox,isSafari} from "@tightrope/lpscripts/browserdetect"
import SimplyTemp from "src/components/modules/simplyTemp"
import Pagestyles from "./css/css.module.scss"


const prdbestData = import("./data/data.json");

export default function nonbmtemplate() {

  let module;
  module = null;
 if(isChrome() || isIeEdge()){
    module = <NonbmA data={prdbestData}></NonbmA>
  }
  else if(isFirefox()){
    module = <NonbmA data={prdbestData}></NonbmA>
  }
  else if(isSafari()){
   module = <SimplyTemp data={prdbestData}></SimplyTemp>;
 }


  let css;
  if(isFirefox()){
    css = `.nonbma-module--nonbma ul.nonbma-module--bullets {
        padding-left: 85px;
        }`
      }

  return(
    <HomepageLayout>
      <Helmet>
<style type="text/css">{`

body {
background-image: url(/assets/framebg-val.jpg);
background-size: cover;
background-repeat: no-repeat !important;
background-position: center center !important;
max-width: 100%;
height: auto ;
min-height: 100%;
width: 100%;
padding: 0;
margin: 0 ;
}

button.trbm_cta {
  font-weight: normal ;
  font-size: 30px ;
}

button {
  padding: 20px 50px;
  font-weight: normal
  padding: 20px 100px  !important;
  border: none  !important;
  background: #43ba19  !important;
  border-radius: 10px  !important;
  display: table  !important;
  margin: 0px auto 15px  !important;
  cursor: pointer  !important;
  color: white  !important;
  font-size: 35px !important;
}

button:hover{
  transform: scale(1.2) !important;
}

.trbm-module--bottom h2,
.trbm-module--howto span, .footerlinks-module--br, .footerlinks-module--br a {
  color: #f1f1f1 ;
}

.trbm-module--bullets {
  width: 550px ;
}

.cws {
  width: 200px ;
  display: block ;
  margin: 10px auto ;
}

#ctadisclosuresearch-module--ctadisclaimersearch  {
  padding: 0px 24% ;
  padding-bottom: 0;
}

#ctadisclosure-module--ctadisclaimer {
  padding: 0px 7% ;
  padding-bottom: 0 ;
}

.nonbma-module--nonbma .nonbma-module--install h2 {
  display: none;
}

.nonbma-module--nonbma .nonbma-module--install {
  padding: 15px;
}

.simply-module--simplyWrap .simply-module--atf {
  background:none !important;
  width:80% !important;
  margin:40px auto 0 !important;
}

.simply-module--simplyWrap h1, .simply-module--simplyWrap p {
  color:#333 !important;
}

button.simply-module--install {
  margin:40px auto !important;
}

.simply-module--minHeight {
  min-height:100vh;
}

`}
   </style>
   <style type="text/css">
        {css}
   </style>

      <title>Freshy Search - freshysearch.com</title></Helmet>
      <div className = {Pagestyles.templatecws}>
      {module}
      </div>
    </HomepageLayout>
  )
}
